<template>
    <div class="couriers">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            {{ $t('couriers.list_of_couriers', { p1: couriersOrig.length, p2: couriersOnlineCount, p3: couriersFreeCount }) }}
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="onClickUpload">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <template>
                        <v-card-title class="py-0 d-flex">
                            <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_statuses" name="order_mode" v-model="filter_status" label="По статусу">
                                <template slot="selection" slot-scope="data">
                                    <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon>{{ data.item.text }}
                                </template>
                                <template slot="item" slot-scope="data">
                                    <v-icon v-if="data.item.icon" small color="#444" class="mr-2">{{ data.item.icon }}</v-icon>{{ data.item.text }}
                                </template>
                            </v-select>
                            <v-select class="mx-2 flex-grow-0" style="width: 220px;" no-data-text="Нет данных" @change="updateData" :items="filter_enables" name="order_mode" v-model="filter_enable" label="По подключению"></v-select>

                            <v-spacer></v-spacer>
                            <v-text-field style="width: 340px; flex: initial" v-model="search" append-icon="mdi-magnify" :label="$t('common.search')" single-line hide-details></v-text-field>
                        </v-card-title>

                        <v-data-table
                            dense
                            no-data-text="Нет данных"
                            no-results-text="Нет данных, попробуйте изменить запрос"
                            :footer-props="{
                                pageText: '{0} - {1} из {2}',
                                itemsPerPageText: 'Показывать на странице',
                                itemsPerPageAllText: 'все',
                                showFirstLastPage: true,
                                itemsPerPageOptions: [25, 50, 100, -1],
                            }"
                            :headers="headers"
                            :search="search"
                            :items="couriers"
                            item-key="index"
                            class="elevation-0"
                            :loading="loading"
                            :loading-text="$t('common.loading')"
                        >
                            <!-- надо разобраться со слотами и выполнить то, что закомментировано тут -->
                            <template v-slot:[`item.tname`]="{ item }">
                                <v-avatar size="24" class="mr-2">
                                  <img
                                    alt="courier"
                                    :src="`${ config.api.delivery + 'user_avatar?tid=' + item.tid }`"
                                  >
                                </v-avatar>
                                <b>{{ item.tname.length > 30 ?  item.tname.substr(0, 30) + '...' : item.tname }}</b>
                            </template>

                            <template v-slot:[`item.enable`]="{ item }">
                                <span :style="{ color: getConnectColor(item.enable) }">{{ getConnectStatus(item.enable) }}</span>
                            </template>

                            <template v-slot:[`item.mynex_ident`]="{ item }">
                                <span :style="{ color: getConnectColor(item.mynex_ident > 0) }">{{ getConnectStatus(item.mynex_ident > 0) }}</span>
                            </template>

                            <template v-slot:[`item.status`]="{ item }">

                                <div class="d-flex">
                                    <v-chip :color="getStatusColorHtml(item.status)" text-color="white">
                                        <v-icon size="small" class="mr-1">{{ getStatusIcon(item.status) }}</v-icon>
                                        {{ getStatusStr(item.status) }}
                                    </v-chip>
                                    <v-btn class="mt-1" v-if="item.status != config.user.status.free || item.enable" outlined text tile x-small @click="onClickStatusEdit(item)"><v-icon small>mdi-pencil-outline</v-icon></v-btn>
                                </div>

                            </template>

                            <template v-slot:[`item.ordersToday`]="{ item }">
                                <span style="width:35px;display: inline-block;">{{ item.ordersToday }}</span>
                                <v-btn v-if="item.ordersToday" outlined text tile x-small @click="onClickTodayDetails(item)" ><v-icon small>mdi-menu</v-icon></v-btn>
                            </template>

                            <template v-slot:[`item.ordersYesterday`]="{ item }">
                                <span style="width:35px;display: inline-block;">{{ item.ordersYesterday }}</span>
                                <v-btn v-if="item.ordersYesterday" outlined text tile x-small @click="onClickYesterdayDetails(item)" ><v-icon small>mdi-menu</v-icon></v-btn>
                            </template>

                            <template v-slot:[`item.ordersMonth`]="{ item }">
                                <span style="width:35px;display: inline-block;">{{ item.ordersMonth }}</span>
                                <v-btn v-if="item.ordersMonth" outlined text tile x-small @click="onClickMonthDetails(item)" ><v-icon small>mdi-menu</v-icon></v-btn>
                            </template>

                        </v-data-table>
                    </template>
                </v-card>
            </v-col>
        </v-row>


        <div class="text-center">
            <v-dialog v-model="statusDialog" width="unset">
                <v-card>
                    <v-card-title class="headline grey lighten-2">
                        {{ dialogHeader }}
                    </v-card-title>

                    <v-card-text>
                        <div class="d-flex px-4 py-5 justify-md-space-around">
                            <v-btn
                                class="ma-2"
                                v-if="currentItem.status != config.user.status.free"
                                :color="getStatusColorHtml(config.user.status.free)"
                                dark
                                @click="onClickCourierFree()"
                                >Свободен</v-btn
                            >

                            <v-btn class="ma-2" v-if="currentItem.status == config.user.status.free" color="error" dark @click="onClickCourierDelete()">Отключить</v-btn>
                        </div>
                        <v-divider></v-divider>
                        <span v-html="dialogContent"></span>
                    </v-card-text>

                </v-card>
            </v-dialog>

            <v-dialog
              v-model="ordersDialog"
              width="720"
              scrollable
            >
              <v-card
              :loading="loadingOrder">

                <template slot="progress">
                  <v-progress-linear
                    indeterminate
                    absolute
                    top
                    color="deep-purple accent-4"
                  ></v-progress-linear>
                </template>

                <v-card-title class="headline grey lighten-2">
                  {{ dialogHeader }}
                </v-card-title>

                <v-card-text class="py-4">
                  <span v-html="dialogContent"></span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" dark @click="updateDetails">Обновить</v-btn>
                  <v-btn color="primary" text @click="ordersDialog = false">Закрыть</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>


        </div>

        <span class="pb-16 d-block" />

    </div>
</template>

<script>
    import config from '../../config'
    import moment from 'moment-timezone'

    moment.locale('ru')
    moment.tz.setDefault(config.timezoneString)

    export default {
        data() {
            return {
                loading: false,
                loadingOrder: false,
                config,
                search: '',
                headers: [
                    {
                        text: 'Имя в Telegram',
                        align: 'start',
                        value: 'tname',
                    },
                    {
                        text: 'Адрес',
                        value: 'address',
                    },
                    {
                        text: 'Текущий статус',
                        value: 'status',
                    },
                    {
                        text: 'Подключение',
                        value: 'enable',
                    },
                    {
                        text: 'Трекер',
                        value: 'mynex_ident',
                    },
                    {
                        text: 'Заказы за сегодня',
                        value: 'ordersToday',
                    },
                    {
                        text: 'Заказы за вчера',
                        value: 'ordersYesterday',
                    },
                    {
                        text: 'Заказы за месяц',
                        value: 'ordersMonth',
                    },
                ],
                couriers: [],
                couriersOrig: [],
                couriersOnlineCount: 0,
                couriersFreeCount: 0,
                ordersDialog: false,
                statusDialog: false,
                dialogHeader: '',
                dialogContent: '',
                currentItem: {},
                filter_status: -1,
                filter_statuses: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Свободен',
                        value: config.user.status.free,
                        icon: 'mdi-check-bold',
                    },
                    {
                        text: 'Обрабатывает',
                        value: config.user.status.processing,
                        icon: 'mdi-timer-sand',
                    },
                    {
                        text: 'Доставляет',
                        value: config.user.status.delivering,
                        icon: 'mdi-run-fast',
                    },
                ],
                filter_enable: -1,
                filter_enables: [
                    {
                        text: '< Все >',
                        value: -1,
                    },
                    {
                        text: 'Отключен',
                        value: 0,
                    },
                    {
                        text: 'Подключен',
                        value: 1,
                    },
                ],
                detailsCourier: 0,
                detailsDate1: '',
                detailsDate2: '',
            }
        },
        components: {},

        mounted() {
            console.log('start!')

            this.uploadData()

        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {},
        methods: {
            onClickCourierFree() {
                let courier = this.couriersOrig.find(courier => courier.tid == this.currentItem.tid)
                console.log('free', courier)

                if (courier) {
                    courier.act_ord = 0
                    courier.status = config.user.status.free

                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            this.couriersFreeCount++
                            this.updateData()
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
                    
                this.$store
                    .dispatch('courier_orders_reset', courier.tid)
                    .then(res => {
                        if (res.data.success) {
                            console.log('order saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                    
                this.statusDialog = false
            },
            onClickCourierDelete() {
                let courier = this.couriersOrig.find(courier => courier.tid == this.currentItem.tid)
                if (courier) {
                    courier.enable = 0

                    this.$store.dispatch('user_set', courier).then(res => {
                        if (res.data.success) {
                            this.updateData()
                            console.log('user saved ok')
                        } else {
                            console.log('order saved fail')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
                }
                        
                this.statusDialog = false
            },
            onClickStatusEdit(item) {
                this.dialogHeader = `Изменить статус для ${item.tname}`
                this.dialogContent = `Текущий статус: <span style="color: ${this.getStatusColorHtml(item.status)};">${this.getStatusStr(item.status)}</span>`
                this.currentItem = item
                this.statusDialog = true
            },
            onClickTodayDetails(courier) {
                this.dialogHeader = `${courier.tname} - заказы за сегодня (${courier.ordersToday})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = moment().format('YYYY-MM-DD')
                this.detailsDate2 = moment().format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            onClickYesterdayDetails(courier) {
                this.dialogHeader = `${courier.tname} - заказы за вчера (${courier.ordersToday})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.detailsDate2 = moment().subtract(1, 'd').format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            onClickMonthDetails(courier) {
                this.dialogHeader = `${courier.tname} - заказы за месяц (${courier.ordersMonth})`
                this.detailsCourier = courier.tid
                this.detailsDate1 = moment().subtract(1, 'month').format('YYYY-MM-DD')
                this.detailsDate2 = moment().format('YYYY-MM-DD')
                this.dialogContent = this.$t('common.loading')
                this.updateDetails()
                this.ordersDialog = true
            },
            updateDetails() {
                this.loadingOrder = true

                this.$store
                    .dispatch('orders_date', {
                        partner: 0,
                        courier: this.detailsCourier,
                        date1: this.detailsDate1,
                        date2: this.detailsDate2 + ' 23:59:59',
                        mode: 0
                    })
                    .then(res => {
                        this.dialogContent = ''
                        if (res.data.success) {
                            console.log('res.data.orders', res.data.orders)
                            res.data.orders.forEach((order, i) => {
                                const time = moment(order.date).format('D MMM HH:mm')
                                const delivery_time = order.date_complete ? ` (${ moment(order.date_complete).format('D MMM HH:mm') })` : ''
                                this.dialogContent += `${ i + 1 }. [${ order.id }] <b>${ order.name }</b> - ${ time } - <span style="color: ${ this.orderStatusColorHtml(order.status) };">${ this.orderStatusStr(order.status) }${ delivery_time }</span><br>`
                            })
                        } else {
                            this.dialogContent = this.$t('common.loading_error', {p1: 1})
                        }
                        this.loadingOrder = false
                    })
                    .catch(err => {
                        this.loadingOrder = false
                        console.log('partner_orders_get error', err)
                        this.dialogContent = this.$t('common.loading_error', {p1: 2})
                    })

            },
            onClickUpload() {
                this.uploadData()
            },
            getConnectColor(status) {
                return status ? '#4CAF50' : '#F44336'
            },
            getConnectStatus(status) {
                return status ? 'Подключен' : 'Отключен'
            },
            getStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.user.status.free:
                        color = '#4CAF50' // green
                        break
                    case config.user.status.processing:
                        color = '#F44336' // red
                        break
                    case config.user.status.delivering:
                        color = '#2196F3' // blue
                        break
                }
                return color
            },
            getStatusColor(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.user.status.free:
                        color = 'green'
                        break
                    case config.user.status.processing:
                        color = 'red'
                        break
                    case config.user.status.delivering:
                        color = 'blue'
                        break
                }
                return color + '--text'
            },
            getStatusIcon(sta) {
                let icon = 'mdi-question'
                switch (sta) {
                    case config.user.status.free:
                        icon = 'mdi-check-bold'
                        break
                    case config.user.status.processing:
                        icon = 'mdi-timer-sand'
                        break
                    case config.user.status.delivering:
                        icon = 'mdi-run-fast'
                        break
                }
                return icon
            },
            getStatusStr(sta) {
                let status = 'Неизвестно'
                switch (sta) {
                    case config.user.status.free:
                        status = 'Свободен'
                        break
                    case config.user.status.processing:
                        status = 'Обрабатывает'
                        break
                    case config.user.status.delivering:
                        status = 'Доставляет'
                        break
                }
                return status
            },
            orderStatusStr(sta) {
                let status = 'Неизвестно'
                switch (sta) {
                    case config.order.status.new:
                        status = 'Новый'
                        break
                    case config.order.status.way_partner:
                        status = 'В пути к партнеру'
                        break
                    case config.order.status.way_guest:
                        status = 'В пути к гостю'
                        break
                    case config.order.status.complete:
                        status = 'Доставлен'
                        break
                    case config.order.status.forming:
                        status = 'Создается'
                        break
                    case config.order.status.passed:
                        status = 'Передан курьеру'
                        break
                }
                return status
            },
            orderStatusColorHtml(sta) {
                let color = 'grey'
                switch (sta) {
                    case config.order.status.new:
                        color = '#9C27B0' // purple
                        break
                    case config.order.status.way_partner:
                        color = '#FF9800' // orange
                        break
                    case config.order.status.way_guest:
                        color = '#2196F3' // blue
                        break
                    case config.order.status.complete:
                        color = '#4CAF50' // green
                        break
                    case config.order.status.forming:
                        color = '#607D8B' // blue-grey
                        break
                    case config.order.status.passed:
                        color = '#F44336' // red
                        break
                }
                return color
            },

            uploadData() {
                this.loading = true
                this.$store
                    .dispatch('couriers_get')
                    .then(res => {
                        if (res.data.success) {
                            this.couriersOnlineCount = 0
                            this.couriersOrig = res.data.couriers.map((courier, i) => {
                                if (courier.enable) this.couriersOnlineCount++
                                courier.address = 'не определен'
                                return courier
                            })
                            this.couriers = JSON.parse(JSON.stringify(this.couriersOrig))
                            this.couriersFreeCount = this.couriers.filter(courier => courier.enable && courier.status == config.user.status.free).length
                            console.log('couriers', this.couriers)
                            this.updateData()
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.loading = false
                        console.log(err)
                    })
            },

            updateData() {
                this.couriers = JSON.parse(JSON.stringify(this.couriersOrig))
                if (this.filter_status != -1) {
                    console.log('this.filter_status', this.filter_status)
                    this.couriers = this.couriers.filter(courier => courier.status == this.filter_status)
                }
                if (this.filter_enable != -1) {
                    this.couriers = this.couriers.filter(courier => courier.enable == this.filter_enable)
                }
            },
        },
    }
</script>

<style lang="stylus">

    .couriers
        height 100%

        .v-card
            cursor default

        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

        .v-data-table
            padding 0 16px
            .v-data-table__wrapper > table > thead > tr
                th
                    padding 0 8px
            .v-data-table__wrapper > table > tbody > tr
                cursor default
                background none !important
                td
                    padding 0 8px
                    font-size 0.75rem
                    p
                        margin-top 6px
                        margin-bottom 6px

        .expanded-content
            box-shadow inset 0px 4px 8px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 8px -5px rgba(50, 50, 50, 0.75)
            background none !important
            &:hover
                cursor default !important

        .th-first-item
            width 30%

        .v-chip
            &.v-size--default
                font-size 0.75rem
                height 20px
                border-radius 16px
                margin 4px

        b
            font-weight 500

    .partners-dialog
        .v-list
            overflow-y auto
            height 132px
            .list-group
                height 100%
                .v-list-item
                    cursor pointer
                    &:hover
                        background-color beige
                .sortable-ghost
                    background-color beige

</style>
